import React, {Suspense, useMemo, useEffect, useState} from 'react';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';
import {useLocation, useNavigate} from 'react-router-dom';

import OverlayHeader from '@/layouts/OverlayHeader';

import Brand from './Brand';
import News from './News';
import Zip from './Zip';
import Loader from '../../components/common/Loader';
import ContentsHeader from '../../layouts/ContentsHeader';
import Popup from './Popup';
import Layout from '../../layouts';
import styles from './styles.module.scss';
import Event from './Event';
interface Props {
  type: 'brand' | 'zip' | 'news' | 'issue' | 'event';
}

export default function ContentsWrapper({type}: Props) {
  const location = useLocation();
  const navigator = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const clsName = useMemo(() => {
    return type === 'brand'
      ? 'content-brand'
      : type === 'zip'
      ? 'content-zip'
      : type === 'news'
      ? 'content-brand news-contents'
      : '';
  }, [type]);

  const onScroll = () => {
    if (window.scrollY > 10) {
      setIsScrolled(() => true);
    } else {
      setIsScrolled(() => false);
    }
  };

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Layout.Main>
      <div className={`${clsName} ${styles.wrapper}`}>
        {type !== 'issue' && type !== 'event' ? (
          <ContentsHeader
            txt={'상세보기'}
            isOption={'home'}
            isBlack={type === 'zip'}
            isBottomLine={type === 'zip' ? 'none' : ''}
            back={() =>
              navigator(
                location.state?.prev ? `/${location.state?.prev}` : '/home',
                {
                  state: {
                    prev: undefined,
                  },
                },
              )
            }
          />
        ) : (
          <OverlayHeader
            isScrolled={isScrolled}
            title={''}
            type={'collection'}
            back={() =>
              location.state?.prev
                ? navigator(`/${location.state?.prev}`)
                : navigator('/home')
            }
          />
        )}
        <Suspense fallback={<Loader />}>
          {type === 'brand' && <Brand />}
          {type === 'zip' && <Zip />}
          {type === 'news' && <News />}
        </Suspense>
        {type === 'issue' && <Popup />}
        {type === 'event' && <Event />}
      </div>
    </Layout.Main>
  );
}
