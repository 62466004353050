import React from 'react';

import {ReactComponent as AllImageIcon} from '@/assets/icons/photo-library.svg';

import styles from './styles.module.scss';
interface Props {
  onClick: () => void;
}

export default function AllImageBtn({onClick}: Props) {
  return (
    <button className={styles.allImageBtn} onClick={onClick}>
      <AllImageIcon />
      <span className={styles.allImageBtnText}>전체 사진</span>
    </button>
  );
}
