import React from 'react';

import {useBrandContext} from '@/services/contexts/brand/BrandContext';
import {ReactComponent as ArrowRight} from '@/assets/icons/more-right-arrow.svg';

import MenuListTitle from './MenuListTitle';
import {MenuItem} from '../../molecules/MenuItem/MenuItem';
import {MenuShortCutBtn} from './menulist.styled';

interface Props {
  menus: MenuItemI[];
  openMenuImg: () => void;
}

export default function MenuList({menus, openMenuImg}: Props) {
  const {brandInfo} = useBrandContext();
  return menus?.length > 0 ? (
    <div className="pt-7 pr-5 pb-15 pl-5 bg-white">
      <MenuListTitle
        descTitle={brandInfo?.descTitle || ''}
        descSubTitle={brandInfo?.descSubtitle || ''}
      />
      <div>
        {menus.map((menu, idx) => {
          return (
            <MenuItem
              key={`present_menu_${idx}`}
              menu={menu}
              isBorder={idx !== menus.length - 1}
              style={{marginTop: '16px'}}
            />
          );
        })}
      </div>
      <div
        style={{
          marginTop: '16px',
          paddingBottom: '60px',
          display: 'flex',
          justifyContent: 'center',
        }}>
        {brandInfo?.menuBoardImageList &&
          brandInfo?.menuBoardImageList.length > 0 && (
            <MenuShortCutBtn onClick={() => openMenuImg()}>
              메뉴판 이미지
              <ArrowRight />
            </MenuShortCutBtn>
          )}
      </div>
    </div>
  ) : (
    <></>
  );
}
