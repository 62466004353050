import React from 'react';

import {MenuTitleDesc, MenuTitleWrapper} from './menulist.styled';
import Text from '../../atoms/Text/Text';

interface Props {
  descTitle: string;
  descSubTitle: string;
}

export default function MenuListTitle({descTitle, descSubTitle}: Props) {
  return (
    <MenuTitleWrapper>
      <Text
        txt={descTitle.replace(/\\n/g, '\n') || '대표 메뉴'}
        fontSize={descTitle ? 24 : 18}
        color={'#000'}
        style={{
          fontWeight: '700',
          lineHeight: '33px',
          letterSpacing: '-0.32px',
          whiteSpace: 'pre-wrap',
          textAlign: 'left',
        }}
      />
      {descSubTitle && <MenuTitleDesc>{descSubTitle}</MenuTitleDesc>}
      {/*!descTitle && menuImgLength > 0 && (
        <button onClick={() => openMenuImg()}>
          <Text
            txt={'메뉴판 이미지로 보기'}
            fontSize={14}
            color={'#9F9CA3'}
            style={{
              fontWeight: '500',
              lineHeight: '150%',
              letterSpacing: '-0.32px',
            }}
          />
        </button>
      )*/}
    </MenuTitleWrapper>
  );
}
