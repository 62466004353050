import React from 'react';
import {useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as MapIcons} from '@/assets/icons/map_open_icon.svg';
import {ReactComponent as BookmarkIcon} from '@/assets/icons/footer-bookmark-icon.svg';
import {ReactComponent as ShareIcon} from '@/assets/icons/footer-share-icon.svg';
import {ReactComponent as FilledBookmark} from '@/assets/icons/filled_bookmark.svg';
import {ReactComponent as NewIcon} from '@/assets/icons/bottom-new-icon.svg';
import {isMobile} from '@/utils';
import {useBrandContext} from '@/services/contexts/brand/BrandContext';
import {useDoBookmark} from '@/hooks/mutations/brands';
import {IsOpenShareSheet} from '@/store';

import {FooterWrapper} from './brandDetailFooter.styled';
import Text from '../../../atoms/Text/Text';

interface Props {
  setClosed: (is: boolean) => void;
}

export default function BrandDetailFooter({setClosed}: Props) {
  const navigator = useNavigate();
  const {brandInfo, benefits, refetchBrand: refetch} = useBrandContext();
  const setIsOpenShareSheet = useSetRecoilState(IsOpenShareSheet);
  const hasBookmark = brandInfo?.isBookmark || false;
  const {mutateAsync} = useDoBookmark();
  const doBookmark = async (isBookmark: boolean) => {
    try {
      await mutateAsync({
        id: Number(brandInfo?.parentId || 0),
        bookmark: isBookmark,
      });
      if (refetch) {
        refetch();
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <FooterWrapper
      mobile={isMobile()}
      style={{
        cursor: 'pointer',
      }}>
      <div className="flex justify-between items-center w-full">
        <div className="flex items-center">
          <button
            onClick={($event: React.MouseEvent<HTMLButtonElement>) => {
              $event.preventDefault();
              doBookmark(!hasBookmark);
            }}>
            {hasBookmark ? (
              <FilledBookmark
                width={20}
                height={20}
                style={{marginRight: '16px'}}
              />
            ) : (
              <BookmarkIcon style={{marginRight: '16px'}} />
            )}
          </button>
          <button
            onClick={($event: React.MouseEvent<HTMLButtonElement>) => {
              $event.preventDefault();
              setIsOpenShareSheet(true);
            }}>
            <ShareIcon />
          </button>
        </div>
        {benefits && benefits.length > 0 ? (
          <div
            className="flex justify-start items-center"
            onClick={() => navigator(`/brand/giftcard/${benefits[0]?.id}`)}>
            <NewIcon style={{marginRight: '8px'}} />
            <Text
              txt={'할인권 받기'}
              fontSize={18}
              color="#fff"
              style={{
                fontWeight: 500,
                letterSpacing: '-0.36px',
              }}
            />
          </div>
        ) : brandInfo?.locationKakao ? (
          <div
            className="flex justify-start items-center"
            onClick={() => setClosed(false)}>
            <MapIcons style={{marginRight: '8px'}} />
            <Text
              txt={'지도앱으로 보기'}
              fontSize={16}
              color="#fff"
              style={{
                letterSpacing: '-0.32px',
              }}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </FooterWrapper>
  );
}
