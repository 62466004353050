import React from 'react';
import {useNavigate} from 'react-router-dom';

import {useBrandContext} from '@/services/contexts/brand/BrandContext';
import {ReactComponent as RightArrowIcon} from '@/assets/icons/benefit-right-arrow.svg';
import {ReactComponent as ShareIcon} from '@/assets/icons/brand-share-icon.svg';
import {ReactComponent as BookmarkIcon} from '@/assets/icons/brand-scrap-gray.svg';
import {ReactComponent as FilledBookmark} from '@/assets/icons/filled_bookmark.svg';
import {useDoBookmark} from '@/hooks/mutations/brands';

import styles from './styles.module.scss';

const BenefitCoupon = () => {
  const navigator = useNavigate();
  const {benefits} = useBrandContext();
  return (
    <div
      className={styles.benefitCoupon}
      style={{cursor: 'pointer'}}
      onClick={() =>
        navigator(`/brand/giftcard/${benefits?.[0]?.id}`, {
          state: {
            prev: location.pathname.replace('/', ''),
          },
        })
      }>
      <div className={styles.leftSide}>
        <img src={benefits?.[0]?.imageUrl} alt="benefitCoupon" />
        <div className={styles.infoTxt}>
          <strong>매장 방문 시 할인받고 가세요</strong>
          <span>{benefits?.[0]?.title}</span>
        </div>
      </div>
      <div className={styles.rightSide}>
        <RightArrowIcon />
      </div>
    </div>
  );
};

interface Props {
  setIsOpenShareSheet: (isOpen: boolean) => void;
}

export default function VisitInfo({setIsOpenShareSheet}: Props) {
  const {brandInfo, benefits, refetchBrand: refetch} = useBrandContext();
  const hasBookmark = brandInfo?.isBookmark || false;
  const {mutateAsync} = useDoBookmark();
  const doBookmark = async (isBookmark: boolean) => {
    try {
      await mutateAsync({
        id: Number(brandInfo?.parentId || 0),
        bookmark: isBookmark,
      });
      if (refetch) {
        refetch();
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className={styles.visitInfoWrapper}>
      <h3>{brandInfo?.title}에 방문 예정이신가요?</h3>
      {benefits && benefits.length > 0 && <BenefitCoupon />}
      <div className={styles.shareBtns}>
        <button
          className={styles.shareBtn}
          onClick={() => setIsOpenShareSheet(true)}>
          <span>브랜드 공유</span>
          <ShareIcon />
        </button>
        <button
          className={styles.bookmarkBtn}
          onClick={() => doBookmark(!hasBookmark)}>
          <span>브랜드 스크랩</span>
          {hasBookmark ? <FilledBookmark /> : <BookmarkIcon />}
        </button>
      </div>
    </div>
  );
}
