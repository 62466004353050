import React, {useRef} from 'react';

import useImageHeight from '@/hooks/useImageHeight';

import 'swiper/css';
import styles from './styles.module.scss';

interface Props {
  images: string[];
  callbackItemClick?: <T>(item: T) => void;
}

export function ImageList({images, callbackItemClick}: Props) {
  const carouselRef = useRef<HTMLDivElement>(null);
  const {imageRef: imgRef} = useImageHeight();

  return (
    <div className={styles.carousel}>
      <div className={styles.images} ref={carouselRef} id={'img'}>
        {images.length > 0 &&
          images.map((o, i) => {
            return (
              <div
                className={styles.brandItem}
                key={`brand_detail_images_${i}`}
                style={{paddingRight: i == images.length - 1 ? '20px' : '0'}}
                onClick={() => (callbackItemClick ? callbackItemClick(o) : '')}>
                <img
                  ref={imgRef}
                  src={o}
                  alt=""
                  style={{height: `160px`, width: '220px'}}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}
