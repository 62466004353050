import React from 'react';

import {ReactComponent as AllImageIcon} from '@/assets/icons/photo-library.svg';

import styles from './styles.module.scss';

interface Props {
  onClick: () => void;
}

export default function AllImageShortcut({onClick}: Props) {
  return (
    <button className={styles.allImageShortcut} onClick={onClick}>
      <AllImageIcon />
    </button>
  );
}
