import React from 'react';

import {ReactComponent as FilledBookmark} from '@/assets/icons/filled_bookmark.svg';
import {ReactComponent as BookMarkIcon} from '@/assets/icons/brand-bookmark-no-fill.svg';

import styles from './styles.module.scss';

interface Props {
  hasBookmark: boolean;
  onClick: (isBookmark: boolean) => void;
}
export default function BookmarkBtn({hasBookmark, onClick}: Props) {
  return (
    <button
      className={styles.bookmarkBtn}
      onClick={() => onClick(!hasBookmark)}>
      {hasBookmark ? <FilledBookmark /> : <BookMarkIcon />}
    </button>
  );
}
