import styled from 'styled-components';

export const MenuTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const MenuTitleDesc = styled.div`
  color: var(--G6, #433e48);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.28px;
  margin: 16px 0;
  text-align: left;
`;

export const MenuShortCutBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: 1px solid var(--G2, #e9e7ec);
  background: #fff;
  height: 40px;
  padding: 12px 12px 12px 16px;
  gap: 8px;
  font-size: 14px;
`;
