import React, {useEffect} from 'react';
import {Browser} from '@capacitor/browser';
import {useNavigate} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';

import {switchNewLineTxt} from '@/utils';
import useLogger, {Action} from '@/hooks/useLogger';

import styles from './styles.module.scss';
interface Props {
  item: MagazineItem;
  isActive: boolean;
}

export default function Banner({item}: Props) {
  const navigator = useNavigate();
  const loadImag = (imgPath: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.onerror = () => {
        reject(null);
      };
      img.src = imgPath;
    });
  };

  const openBrowser = async (url: string) => {
    if (Capacitor.getPlatform() === 'web') {
      window.open(url, '_blank');
    } else {
      await Browser.open({
        url,
      });
    }
  };
  const sender = useLogger();

  const moveToContents = (item: MagazineItem) => {
    sender({
      _msg: 'ToBanner',
      _screen: '/Banner/' + item.id,
      _action: Action.CLICK,
      _data: {to: item},
    });
    sender({
      _msg: 'homebanner_' + item.id,
      _screen: '/Banner/' + item.id,
      _action: Action.CLICK,
      _data: {to: item},
    });
    if (item.category === 'news' && item.link.length > 0) {
      openBrowser(item.link);
    } else {
      navigator(
        item.category === 'brands'
          ? `/knewbrands/${item.id}`
          : `/${item.category}/${item.id}`,
        {
          state: {
            prev: 'home',
          },
        },
      );
    }
  };

  useEffect(() => {
    if (item.imageUrl) {
      loadImag(item.bannerImageUrl ? item.bannerImageUrl : item.imageUrl);
    }
  }, [item]);

  return (
    <div
      className={styles['home-banner-content']}
      onClick={() => moveToContents(item)}
      style={{cursor: item.category !== 'showroom' ? 'pointer' : 'default'}}>
      <div className={styles['img-wrapper']}>
        <img src={item.bannerImageUrl ? item.bannerImageUrl : item.imageUrl} />
      </div>
      <div className={styles['txt-wrapper']}>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: switchNewLineTxt(
              item.bannerTitle ? item.bannerTitle : item.title,
            ),
          }}
        />
        <div className={styles.desc}>{item.subtitle}</div>
      </div>
    </div>
  );
}
