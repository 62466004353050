import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {useBrandContext} from '@/services/contexts/brand/BrandContext';
import {useDoBookmark} from '@/hooks/mutations/brands';
import defaultBrandImage from '@/assets/icons/brand-default-background.png';
import {AddressList} from '@/store';

import styles from './styles.module.scss';
import {ImageList} from './ImageList';
import BookmarkBtn from './BookmarkBtn';
import AllImageShortcut from './AllImageShortcut';
import BenefitBtn from './BenefitBtn';
import AllImageBtn from './AllImageBtn';

interface Props {
  setIsShowAllImage: (is: boolean) => void;
}
export function BrandTop({setIsShowAllImage}: Props) {
  const navigator = useNavigate();
  const {brandInfo, benefits, refetchBrand: refetch} = useBrandContext();
  const addressList = useRecoilValue(AddressList);
  const {mutateAsync} = useDoBookmark();
  const images = [...(brandInfo?.imageList || [])].splice(0, 5);
  const doBookmark = async (isBookmark: boolean) => {
    try {
      await mutateAsync({
        id: Number(brandInfo?.parentId || 0),
        bookmark: isBookmark,
      });
      if (refetch) {
        refetch();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const backgroundImage = brandInfo?.backgroundImage;
  const carouselHeight = () => {
    return 518;
  };

  const findAddressObj = (id: number, list: Address[]): Address => {
    let addressInfo: Address = {
      id: -1,
      address: '',
      child: [],
    };
    list.forEach(address => {
      if (id === address.id) {
        addressInfo = address;
      }
    });
    return addressInfo;
  };

  const shortAddress = useMemo(() => {
    let address1Item;
    let address2Item;
    if (addressList.length > 0 && brandInfo?.address1) {
      address1Item = '서울시';
      address2Item = findAddressObj(brandInfo?.address2, addressList);
      return `${address1Item} ${address2Item.address}`;
    }
    return '';
  }, [addressList, brandInfo]);

  return (
    <div
      style={{
        height: carouselHeight(),
        background: `url(${
          backgroundImage ? backgroundImage : defaultBrandImage
        }) no-repeat center / cover`,
      }}>
      <div className={styles.container}>
        <div className={styles.title}>{brandInfo?.title}</div>
        <div className={styles.address}>{shortAddress}</div>
        <div className={styles.subtitle}>
          {brandInfo?.subtitle
            ? brandInfo?.subtitle.split('\\n').join('\n')
            : ''}
        </div>
        <ImageList images={images} />
        {benefits && benefits.length > 0 && (
          <div className={styles.btnWrapper}>
            <BenefitBtn
              onClick={() => navigator(`/brand/giftcard/${benefits[0]?.id}`)}
            />
            <AllImageShortcut onClick={() => setIsShowAllImage(true)} />
            <BookmarkBtn
              hasBookmark={brandInfo?.isBookmark || false}
              onClick={(is: boolean) => doBookmark(is)}
            />
          </div>
        )}
        {!benefits ||
          (benefits.length === 0 && (
            <div className={styles.btnWrapper}>
              <AllImageBtn onClick={() => setIsShowAllImage(true)} />
              <BookmarkBtn
                hasBookmark={brandInfo?.isBookmark || false}
                onClick={(is: boolean) => doBookmark(is)}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
