import React from 'react';

import {useBrandContext} from '@/services/contexts/brand/BrandContext';

import styles from './styles.module.scss';
export default function Issue() {
  const {brandInfo} = useBrandContext();
  const issueInfo = brandInfo?.infoIssue?.info;
  const issueTitle = brandInfo?.infoIssue?.title;
  const images = brandInfo?.infoIssue?.imageList;
  return brandInfo?.infoIssue ? (
    <div className={styles.issue}>
      <h3>{`ISSUE`}</h3>
      <h2 className={styles.issueTitle}>{`${issueTitle
        ?.split('\\n')
        .join('\n')}`}</h2>
      <div className={styles.issueContentWrapper}>
        {Object.keys(issueInfo || {}).map((key, index) => (
          <div
            key={`editor_tip_${index}`}
            className={styles.issueContent}
            style={{textAlign: 'left'}}>{`${issueInfo?.[
            key as keyof typeof issueInfo
          ]
            .split('\\n')
            .join('\n')}`}</div>
        ))}
      </div>
      <div className={styles.imageWrapper}>
        <img src={images?.[0]} alt="issue_image" />
      </div>
    </div>
  ) : (
    <></>
  );
}
