import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router';

import {ReactComponent as NIcon} from '@/assets/icons/brand_list_n_icon.svg';
import LocationIcon from '@/components/brandListItem/components/LocationIcon';
import LocationIconRed from '@/components/brandListItem/components/LocationIconRed';
import useGeoLocation from '@/hooks/useGeoLocation';

import styles from './styles.module.scss';
import Image from '../v2/ui/atoms/Image/Image';

interface Props {
  item: BrandListItemType;
  itemStyle?: CSSProperties;
  isCurrentLocation: boolean;
  isBlur?: boolean;
  isScrollend?: boolean;
  isDisabled?: boolean;
}

export default function BrandListItem({
  item,
  itemStyle,
  isCurrentLocation,
  isBlur,
  isScrollend,
  isDisabled,
}: Props) {
  const navigator = useNavigate();
  const {calcWalkMinutes} = useGeoLocation();
  const {title, imageList: images, info} = item;
  const carouselRef = useRef<HTMLDivElement>(null);
  const [imgWidth, setImgWidth] = useState(0);
  const [minImgWidth, setMinImgWidth] = useState(0);
  const calcMargin = (idx: number) => {
    if (idx === 0) {
      return '0 2px 0 20px';
    } else if (idx === 4) {
      return '0 20px 0 0';
    }
    return '0 2px 0 0';
  };
  useEffect(() => {
    if (document.getElementById('main')) {
      const ratio = 0.624;
      const minRatio = 0.384;
      const _width =
        Math.ceil(
          (document.getElementById('main')?.offsetWidth || 0) * ratio,
        ) || 234;
      const _minWidth =
        Math.ceil(
          (document.getElementById('main')?.offsetWidth || 0) * minRatio,
        ) || 144;
      setImgWidth(_width);
      setMinImgWidth(_minWidth);
    }
  }, []);

  return (
    <div
      className={styles.wrapper}
      onClick={() => {
        if (!isDisabled || !isBlur) {
          navigator(`/brand/${item.parentId}/${item.id}`, {
            state: {
              brand: item,
              prev: location.pathname.replace('/', ''),
              scrollEnabled: true,
            },
          });
        }
      }}
      style={itemStyle}>
      {isBlur && (
        <div className={`${styles.not_login}`}>
          <div
            className={`${styles.anim_container} ${
              isScrollend ? styles.fade_in_up : ''
            }`}>
            <strong>
              단 3초 만에 로그인하고
              <br />더 많은 브랜드를 확인하세요.
            </strong>
            <button onClick={() => navigator('/login')}>
              <span>카카오계정으로 간편하게</span>
              로그인하고 모두 보기
            </button>
          </div>
        </div>
      )}
      <div className={styles.imgWrapper} style={{height: '100%'}}>
        <div ref={carouselRef}>
          {images &&
            images.slice(0, 5).map((image, i) => {
              return (
                <Image
                  key={`image_ref_${i}`}
                  src={image}
                  width={i === 0 ? imgWidth : minImgWidth}
                  height={minImgWidth}
                  style={{
                    minWidth: i === 0 ? `${imgWidth}px` : `${minImgWidth}px`,
                    height: `${minImgWidth}px`,
                    margin: calcMargin(i),
                    objectFit: 'cover',
                    borderRadius: '4px',
                  }}
                />
              );
            })}
        </div>
      </div>
      <div className={styles.titleWrapper} style={{margin: '12px 20px 0 20px'}}>
        <strong className="flex items-center">
          {title}
          {item.state === 'contracted' && <NIcon style={{marginLeft: '5px'}} />}
        </strong>
        {item.benefits && (
          <span className={styles.benefit}>{item.benefits}</span>
        )}
      </div>
      <div
        className={styles.locationWrapper}
        style={{margin: '8px 20px 0 20px'}}>
        {isCurrentLocation ? <LocationIconRed /> : <LocationIcon />}
        <span
          style={{
            marginLeft: '2px',
            color: '#000000',
          }}>{`${
          isCurrentLocation && calcWalkMinutes
            ? calcWalkMinutes(item.distance || 0)
            : item.locationInfo || ''
        } ∙ ${item.keyword || ''}`}</span>
      </div>
      <div className={styles.infoWrapper} style={{margin: '8px 20px 0 20px'}}>
        {info}
      </div>
    </div>
  );
}
