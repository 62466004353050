import React, {useRef} from 'react';
import cc from 'classcat';

import useImageHeight from '@/hooks/useImageHeight';
import {addComma} from '@/utils';

import styles from './styles.module.scss';

interface Props {
  wrapperStyle?: React.CSSProperties;
  title: string;
  titleWrapperStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  subtitleStyle?: React.CSSProperties;
  link?: string;
  totalCnt?: number;
  subtitle?: string;
  images: {
    id?: number;
    parentId?: number;
    url: string;
    title: string;
    description: string;
    price?: number;
    discountNumber?: number;
    keyword?: string;
  }[];
  disableDescription?: boolean;
  withTwoLineTitle?: boolean;
  callbackItemClick?: <T>(item: T) => void;
  callbackMoreIcon?: () => void;
  isTogether?: boolean;
}

export default function ImageRail({
  wrapperStyle,
  title,
  subtitle,
  titleWrapperStyle,
  titleStyle,
  subtitleStyle,
  link,
  images,
  totalCnt,
  disableDescription,
  withTwoLineTitle,
  callbackItemClick,
  callbackMoreIcon,
}: Props) {
  const carouselRef = useRef<HTMLDivElement>(null);
  const {height: imgHeight, imageRef: imgRef} = useImageHeight();

  return (
    <div className={styles.wrapper} style={wrapperStyle}>
      <div className={styles.titleWrapper} style={titleWrapperStyle}>
        <div className={styles.title} style={titleStyle}>
          <h3>{title}</h3>
          {subtitle && (
            <span className={styles.subtitle} style={subtitleStyle}>
              {subtitle}
            </span>
          )}
        </div>
        {!!totalCnt && totalCnt > 5 && (
          <div
            className={styles.more}
            onClick={() => (callbackMoreIcon ? callbackMoreIcon() : '')}
            style={{display: link ? 'block' : 'none', cursor: 'pointer'}}>
            <span>더보기</span>
          </div>
        )}
      </div>
      <div className={styles.carousel}>
        <div className={styles.images} ref={carouselRef} id={'img'}>
          {images.length > 0 &&
            images.map((o, i) => {
              return (
                <div
                  className={styles.brandItem}
                  key={`${o.url}_${i}`}
                  onClick={() =>
                    callbackItemClick ? callbackItemClick(o) : ''
                  }>
                  <img
                    ref={imgRef}
                    src={o.url}
                    alt=""
                    style={{height: `${imgHeight}px`}}
                  />
                  <div
                    className={cc([
                      styles.itemTitle,
                      withTwoLineTitle && styles.twoLines,
                    ])}>
                    {o.title}
                  </div>
                  {!disableDescription && (
                    <p className={styles.itemDesc}>{o.description}</p>
                  )}
                  {o?.price && (
                    <p className={styles.itemPrice}>
                      {!!o?.discountNumber && <span>{o?.discountNumber}%</span>}
                      {addComma(o?.price)}
                    </p>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
