import React, {Suspense, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import BrandPage from '@/components/v2/ui/templates/detail/BrandPage';
import MenuList from '@/components/v2/ui/organisms/MenuList/MenuList';
import PlaceInfo from '@/components/Brand/PlaceInfo';
import RecommendBrand from '@/components/Brand/RecommendBrand';
import BrandShareBottomSheet from '@/components/brandShareBottomSheet';
import BrandImageSlider from '@/components/v2/ui/organisms/BrandImageSlider/BrandImageSlider';
import BrandAllImage from '@/components/v2/ui/organisms/BrandAllImage/BrandAllImage';
import {useBrandContext} from '@/services/contexts/brand/BrandContext';
import Layout from '@/layouts';
import {OverlayHeader} from '@/components/v2/ui/templates/layouts/headers/OverlayHeader';
import {IsOpenShareSheet} from '@/store';

import {BrandTop} from './components/BrandTop';
import EditorTip from './components/EditorTip';
import Issue from './components/Issue';
import styles from './components/styles.module.scss';
import VisitInfo from './components/VisitInfo';
export function PlaceV2() {
  const navigator = useNavigate();
  const {brandInfo: data} = useBrandContext();
  const [isOpenShareSheet, setIsOpenShareSheet] =
    useRecoilState(IsOpenShareSheet);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShowImageSlide, setIsShowImageSlide] = useState(false);
  const [isShowAllImage, setIsShowAllImage] = useState(false);
  const [images, setImages] = useState<{src: string}[]>([]);
  const [selectedImg, setSelectedImg] = useState<number>(1);
  // const [carouselIndex, setCarouselIndex] = useState(1);

  const onScroll = () => {
    if (window.scrollY > 10) {
      setIsScrolled(() => true);
    } else {
      setIsScrolled(() => false);
    }
  };

  const changeBrandInfo = (_brandInfo: BrandListItemType) => {
    navigator(`/brand/${_brandInfo.parentId}/${_brandInfo.id}`);
  };

  const openMenuImageSlider = () => {
    setIsShowImageSlide(true);
    setSelectedImg(0);
    setImages(
      data?.menuBoardImageList.map(o => {
        return {
          src: o,
        };
      }) || [],
    );
  };

  const onClickShareBtn = () => setIsOpenShareSheet(true);

  const moveToBack = () => {
    if (isShowAllImage) {
      document.body.style.overflow = 'auto';
      return setIsShowAllImage(false);
    }
    if (isShowImageSlide) {
      document.body.style.overflow = 'auto';
      return setIsShowImageSlide(false);
    }
    navigator(-1);
  };

  const toggleShowSlideImg = (selectedImgIdx: number) => {
    setSelectedImg(selectedImgIdx);
    document.body.style.overflow = 'auto';
    const imgs = (data?.imageList || []).map(o => {
      return {
        src: o,
      };
    });
    setImages(imgs);
    setIsShowAllImage(false);
    setIsShowImageSlide(true);
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  if (!data) {
    return <div></div>;
  }

  if (isShowAllImage) {
    return (
      <Layout.Main>
        <OverlayHeader isScrolled={false} title={''} back={moveToBack} />
        <BrandAllImage
          images={data.imageList || []}
          setSelectedImage={(idx: number) => toggleShowSlideImg(idx)}
        />
      </Layout.Main>
    );
  }

  if (isShowImageSlide) {
    return (
      <Layout.Main>
        <OverlayHeader isScrolled={false} title={''} back={moveToBack} />
        <BrandImageSlider
          images={images}
          selectedImg={selectedImg}
          setSelectedImg={setSelectedImg}
          close={() => setIsShowImageSlide(false)}
        />
      </Layout.Main>
    );
  }

  return (
    <BrandPage
      isScrolled={isScrolled}
      onClickShare={onClickShareBtn}
      title={data.title}
      back={moveToBack}>
      <BrandTop setIsShowAllImage={setIsShowAllImage} />
      <div className="relative" style={{zIndex: 8, background: '#F7F7FC'}}>
        <MenuList
          menus={data?.menuList || []}
          openMenuImg={() => openMenuImageSlider()}
        />
        {data.infoTip && (
          <EditorTip moreImage={() => setIsShowAllImage(true)} />
        )}
        {data.infoIssue && <Issue />}
        <div className={styles.issueDivider}></div>
        <PlaceInfo
          placeInfo={{
            address: data.address || '',
            openingHours: (data.openingHours || '').split('\\n') || [''],
            brandTel: data.brandTel || '',
            brandLink: data.brandLink || '',
            reserveLink: data.reserveLink || '',
            name: data.title || '',
          }}
        />
        <VisitInfo setIsOpenShareSheet={setIsOpenShareSheet} />
        <Suspense>
          <RecommendBrand
            brandId={data.id || -1}
            item={data}
            reload={(brandInfo: BrandListItemType) =>
              changeBrandInfo(brandInfo)
            }
          />
        </Suspense>
      </div>
      <BrandShareBottomSheet
        isOpen={isOpenShareSheet}
        setIsOpen={setIsOpenShareSheet}
        item={data}
      />
    </BrandPage>
  );
}
