import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {Geolocation, GeolocationPosition} from '@capacitor/geolocation';
import {PermissionStatus} from '@capacitor/geolocation/dist/esm/definitions';
import {useRecoilState} from 'recoil';

import {useGetBrandSquare} from '@/hooks/queries/brands';
import {IsOnlyBenefit, SelectedCategory} from '@/store';
import queryKeys from '@/hooks/queries/brands/queryKeys';
import {isMobile} from '@/utils';

// const BRAND_LIST_MAX_HEIGHT = window.innerHeight - 182;
const INITIAL_LIST_HEIGHT = isMobile() ? 330 : 314;
// const LIST_MIN_HEIGHT = 85;

export interface Coordinates {
  latitude: number | null;
  longitude: number | null;
  error: string | null;
}

export interface GeoLocationContextType {
  coordinates: Coordinates;
  permissionState: PermissionStatus | undefined;
  isPossibleGeolocation: boolean;
  isGeolocationModalOpen: boolean;
  isAreaSelected: boolean;
  prevCoords: number[];
  calcWalkMinutes: (distance: number) => string;
  getCurrentPosition: () => Promise<void>;
  checkGeolocation: () => Promise<void>;
  openGeolocationChecker: () => Promise<void>;
  setIsGeolocationModalOpen: (is: boolean) => void;
  setIsAreaSelected: (is: boolean) => void;
  setPrevCoords: (coords: number[]) => void;
  setCoordinates: (coords: Coordinates) => void;
  height: number;
  setHeight: (v: number) => void;
  scrollRef: React.MutableRefObject<HTMLInputElement | null>;
  isScrollLock: boolean;
  setIsScrollLock: (is: boolean) => void;
  fetchOptions: SquareBrandsParam;
  setFetchOptions: (f: SquareBrandsParam) => void;
  brands: BrandListItemType[] | undefined;
  isLoading: boolean;
  isRenderComplete: boolean;
  setIsRenderComplete: (v: boolean) => void;
  map: any;
  setMap: (_map: any) => void;
  isOnlyBenefit: boolean;
  setIsOnlyBenefit: (v: boolean) => void;
  selectedCategory: string;
  setSelectedCategory: (v: string) => void;
  filteredBrandList: BrandListItemType[] | undefined;
  originCoords: Coordinates | undefined;
  zoomLevel: number;
  setZoomLevel: (v: number) => void;
  movedCoordinates: Coordinates;
  setMovedCoordinates: (v: Coordinates) => void;
}

export const GeoLocationContext = createContext<
  GeoLocationContextType | undefined
>(undefined);

export const GeoLocationProvider: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const [coordinates, setCoordinates] = useState<Coordinates>({
    latitude: null,
    longitude: null,
    error: null,
  });
  const [movedCoordinates, setMovedCoordinates] = useState<Coordinates>({
    latitude: null,
    longitude: null,
    error: null,
  });
  const [permissionState, setPermissionState] = useState<PermissionStatus>();
  const [isAreaSelected, setIsAreaSelected] = useState(false);
  const [isGeolocationModalOpen, setIsGeolocationModalOpen] = useState(false);
  const [isScrollLock, setIsScrollLock] = useState(true);
  const [prevCoords, setPrevCoords] = useState<number[]>([]);
  const scrollRef = useRef<HTMLInputElement | null>(null);
  const [height, setHeight] = useState(INITIAL_LIST_HEIGHT); // 초기 높이
  const [isRenderComplete, setIsRenderComplete] = useState(false);
  const [map, setMap] = useState<any>();
  const [selectedCategory, setSelectedCategory] =
    useRecoilState(SelectedCategory);
  const [originCoords, setOriginCoords] = useState<Coordinates>();
  const [zoomLevel, setZoomLevel] = useState(15);
  const [isOnlyBenefit, setIsOnlyBenefit] = useRecoilState(IsOnlyBenefit);
  const [fetchOptions, setFetchOptions] = useState<SquareBrandsParam>({
    limit: 9999,
    offset: 0,
    point1: '',
    point2: '',
  });
  const {data: brands, isLoading} = useGetBrandSquare(fetchOptions, {
    enabled: !!fetchOptions.point1 && !!fetchOptions.point2,
    queryKey: queryKeys.getSqureBrandList(fetchOptions),
  });

  const filteredBrandList = useMemo(() => {
    const list =
      brands && brands.length > 0
        ? brands?.filter(item => {
            const isInCategory =
              selectedCategory == 'All'
                ? true
                : item.categoryList.includes(selectedCategory);
            const hasBenefit = isOnlyBenefit
              ? item.state == 'contracted'
              : true;
            return isInCategory && hasBenefit;
          })
        : brands;
    return list;
  }, [selectedCategory, map, brands, isOnlyBenefit]);

  const calcWalkMinutes = (distance: number): string => {
    if (distance < 500) {
      return `도보 5분 이하`;
    } else if (distance >= 500 && distance < 1000) {
      return `도보 15분 이하`;
    } else if (distance >= 1000 && distance < 2000) {
      return `도보 20분 이하`;
    } else if (distance >= 2000 && distance < 3000) {
      return `도보 20분 이상`;
    } else {
      return `검색 결과가 없습니다`;
    }
  };

  const isPossibleGeolocation = useMemo(() => {
    if (isAreaSelected) {
      return false;
    }
    if (permissionState && permissionState.location !== 'granted') {
      return false;
    }
    return true;
  }, [isAreaSelected, permissionState]);

  const getCurrentPosition = async () => {
    try {
      const position: GeolocationPosition =
        await Geolocation.getCurrentPosition({
          enableHighAccuracy: false,
          maximumAge: 5000,
        });
      console.log(position);
      setCoordinates({
        latitude: Math.abs(position.coords.latitude),
        longitude: Math.abs(position.coords.longitude),
        error: null,
      });
      setOriginCoords({
        latitude: Math.abs(position.coords.latitude),
        longitude: Math.abs(position.coords.longitude),
        error: null,
      });
    } catch (error: any) {
      setCoordinates({
        latitude: null,
        longitude: null,
        error: error.message,
      });
    }
  };

  const checkGeolocation = async () => {
    try {
      const permission = await Geolocation.checkPermissions();
      setPermissionState(permission);
    } catch (e) {
      setIsGeolocationModalOpen(true);
      setIsAreaSelected(true);
    }
  };

  const openGeolocationChecker = async () => {
    await Geolocation.requestPermissions({
      permissions: ['location'],
    });
  };

  useEffect(() => {
    getCurrentPosition();
    checkGeolocation();
  }, []);

  return (
    <GeoLocationContext.Provider
      value={{
        coordinates,
        permissionState,
        isPossibleGeolocation,
        isGeolocationModalOpen,
        isAreaSelected,
        prevCoords,
        calcWalkMinutes,
        getCurrentPosition,
        checkGeolocation,
        openGeolocationChecker,
        setIsGeolocationModalOpen,
        setIsAreaSelected,
        setPrevCoords,
        setCoordinates,
        scrollRef,
        height,
        setHeight,
        isScrollLock,
        setIsScrollLock,
        fetchOptions,
        setFetchOptions,
        brands,
        isLoading,
        isRenderComplete,
        setIsRenderComplete,
        map,
        setMap,
        selectedCategory,
        setSelectedCategory,
        isOnlyBenefit,
        setIsOnlyBenefit,
        filteredBrandList,
        originCoords,
        zoomLevel,
        setZoomLevel,
        movedCoordinates,
        setMovedCoordinates,
      }}>
      {children}
    </GeoLocationContext.Provider>
  );
};
