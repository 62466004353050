import {atom, selector} from 'recoil';
import {MutableRefObject} from 'react';

import {BrandListArguments} from '@/views/brand/list/List';

import {getBrands, getBrandsInHome, getNewBrands} from '../services/brands';
import {user} from './user';
import {BrandItems} from '../views/brand/home/Home';

export const BrandSortInfo = atom<BrandSortItems[]>({
  key: 'brand/sort-list',
  default: [
    {
      txt: '최신순',
      value: 'newest',
      isSelect: true,
    },
    {
      txt: '인기순',
      value: 'famous',
      isSelect: false,
    },
  ],
});

export const BrandAddr2 = atom<number | undefined>({
  key: 'brands/addr2',
  default: 16,
});

export const BrandAddr3 = atom<number | undefined>({
  key: 'brands/addr3',
  default: undefined,
});

export const IsAreaSelected = atom<boolean>({
  key: 'brands/is-area-selected',
  default: false,
});

export const BrandListItems = atom<BrandListItemType[]>({
  key: 'brands/list',
  default: [],
});

export const BrandFamousAreas = atom<FamousAddress[]>({
  key: 'brands/famous-list',
  default: [],
});

export const PopularAreas = selector<FamousAddress[]>({
  key: 'brands/popular-areas',
  get: async ({get}) => {
    const accessToken = get(user);
    const res = await getBrandsInHome(accessToken, 0, 20);
    const famousAddress: FamousAddress[] = [];
    const keys = Object.keys(res.data);
    keys.forEach((k, i) => {
      if (res.data[k].data.length > 0) {
        famousAddress.push({
          id: i,
          address: k,
          address1: res.data[k].address1 || -1,
          address2: res.data[k].address2 || -1,
        });
      }
    });
    return famousAddress;
  },
});

export const BrandAreaItems = atom<BrandItems>({
  key: 'brands/area',
  default: {},
});

export const NewBrandListParam = atom<{
  limit: number;
  offset: number;
  type: 'special' | 'new';
  sort: 'newest' | 'famous';
}>({
  key: 'brands/new-brand-list-param',
  default: {
    limit: 20,
    offset: 0,
    type: 'new',
    sort: 'newest',
  },
});

export const NewBrandList = selector<BrandListItemType[]>({
  key: 'brands/new-brand-list-items',
  get: async ({get}) => {
    const param = get(NewBrandListParam);
    const accessToken = get(user);
    const res = await getNewBrands(accessToken, {
      ...param,
      address_1: -1,
      address_2: -1,
      type: 'new',
    });
    return res.data.results;
  },
});

export const GetNearPlaceParams = atom<BrandListParam>({
  key: 'brands/near-place-params',
  default: {
    limit: 20,
    offset: 0,
    sort: 'newest',
  },
});

export const NearPlaceMyId = atom<number>({
  key: 'brands/near-place-my-id',
  default: -1,
});

export const NearPlaceList = selector<
  {
    id?: number;
    parentId?: number;
    url: string;
    title: string;
    description: string;
    keyword?: string;
  }[]
>({
  key: 'brands/near-place-list',
  get: async ({get}) => {
    const param = get(GetNearPlaceParams);
    const accessToken = get(user);
    const myId = get(NearPlaceMyId);
    const res = await getBrands(accessToken, param);
    return res.data.results
      .map((o: BrandListItemType) => {
        return {
          id: o.id,
          parentId: o.parentId,
          url: o.imageList[0],
          title: o.title,
          description: o.info,
          keyword: o.keyword,
        };
      })
      .filter((o: BrandListItemType) => {
        return o.id !== myId;
      });
  },
});

export const BrandMapUrl = atom<string>({
  key: 'brand/map-url',
  default: '',
});

export const BrandMapLocation = atom<number[]>({
  key: 'brand/map-location',
  default: [],
});

export const UserGeoLocation = atom<number[]>({
  key: 'brand/user-geolocation',
  default: [],
});

export const CurrentOffsetForBrandList = atom<number>({
  key: 'brand/current-offset-for-brand-list',
  default: 0,
});

export const CurrentLongitude = atom<number | undefined>({
  key: 'brand/current-longitude',
  default: undefined,
});

export const CurrentLatitude = atom<number | undefined>({
  key: 'brand/current-latitude',
  default: undefined,
});

export const SelectedCategory = atom<string>({
  key: 'brand/selected-category',
  default: 'All',
});

export const BrandAreaListPage = atom<number>({
  key: 'brand/brand-area-list-page',
  default: 0,
});

export const IsOnlyBenefit = atom<boolean>({
  key: 'brand/is-only-benefit',
  default: false,
});

export const BrandsScroll = atom<MutableRefObject<HTMLElement | null>>({
  key: 'brand/scroll-ref',
  default: undefined,
});

export const IsOpenShareSheet = atom<boolean>({
  key: 'brand/is-open-share-sheet',
  default: false,
});

export const AreaBrandListArgs = selector<BrandListArguments>({
  key: 'brand/area-list-args',
  get: ({get}) => {
    const address2 = get(BrandAddr2);
    const address3 = get(BrandAddr3);
    const limit = 50;
    const offset = get(CurrentOffsetForBrandList);
    const sort = 'newest';
    const lon = get(CurrentLongitude);
    const lat = get(CurrentLatitude);
    const category = get(SelectedCategory);
    const isOnlyBenefit = get(IsOnlyBenefit);
    const filter = isOnlyBenefit ? 'product' : undefined;
    console.log(filter);
    return {
      address2,
      address3,
      limit,
      offset,
      sort,
      lon,
      lat,
      category,
      filter,
    };
  },
});
