import React, {useState} from 'react';

import Layout from '@/layouts';

import {OverlayHeader} from '../layouts/headers/OverlayHeader';
import BrandDetailFooter from '../layouts/brandDetailFooter/BrandDetailFooter';
import MapBottomSheet from '../../organisms/Modal/MapModal';

interface Props {
  onClickShare?: () => void;
  isScrolled: boolean;
  title: string;
  children: React.ReactNode;
  back?: () => void;
}

export default function BrandPage({isScrolled, title, children, back}: Props) {
  const [closed, setClose] = useState(true);
  const close = () => {
    if (!closed) {
      setClose(true);
    }
  };
  return (
    <Layout.Main>
      <OverlayHeader isScrolled={isScrolled} title={title} back={back} />
      {children}
      <BrandDetailFooter setClosed={setClose} />
      <MapBottomSheet close={close} closed={closed} name={title} />
    </Layout.Main>
  );
}
