import React from 'react';

import {ReactComponent as BenefitIcon} from '@/assets/icons/n-icon-benefit.svg';

import styles from './styles.module.scss';
interface Props {
  onClick: () => void;
}
export default function BenefitBtn({onClick}: Props) {
  return (
    <button className={styles.benefitBtn} onClick={onClick}>
      <BenefitIcon />
      <span>할인받고 방문하기</span>
    </button>
  );
}
