import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Capacitor} from '@capacitor/core';
import {Browser} from '@capacitor/browser';

import {ReactComponent as ScrolledBackBtnIcon} from '@/assets/icons/arrow-right.svg';
import {ReactComponent as BackBtnIcon} from '@/assets/icons/overlay_header_back_icon.svg';
import {ReactComponent as KakaoBlackIcon} from '@/assets/icons/overlay_header_kakao_black.svg';
import {ReactComponent as HomeIcon} from '@/assets/icons/overlay_home_icon.svg';
import {isAndroid, isMobile} from '@/utils';

import OverlayHome from '../../../atoms/Icons/OverlayHome';
import OverlayShareIcon from '../../../atoms/Icons/OverlayShareIcon';
import {
  BackButton,
  ContentHeader,
  HeaderTitle,
  ShareButton,
} from './header.styled';

interface Props {
  isScrolled?: boolean;
  title?: string;
  type?: 'collection' | 'brand';
  back?: () => void;
  clickShare?: () => void;
  clickHome?: boolean;
}

export function OverlayHeader({
  isScrolled,
  title,
  type,
  back,
  clickShare,
  clickHome = true,
}: Props) {
  const navigator = useNavigate();
  const openBrowser = async () => {
    if (Capacitor.getPlatform() === 'web') {
      window.open('http://pf.kakao.com/_qmUqG/chat', '_blank');
    } else {
      await Browser.open({
        url: 'http://pf.kakao.com/_qmUqG/chat',
      });
    }
  };
  return (
    <ContentHeader
      android={isAndroid()}
      scrolled={isScrolled}
      mobile={isMobile()}>
      <BackButton
        scrolled={isScrolled}
        onClick={() => (back ? back() : navigator(-1))}>
        {isScrolled ? (
          <ScrolledBackBtnIcon fontSize={20} />
        ) : (
          <BackBtnIcon fontSize={20} />
        )}
      </BackButton>
      {isScrolled && <HeaderTitle>{title}</HeaderTitle>}
      <div className="flex">
        {clickShare && (
          <ShareButton scrolled={isScrolled} onClick={() => clickShare()}>
            <OverlayShareIcon
              fontSize={20}
              fill={isScrolled ? '#000' : 'white'}
            />
          </ShareButton>
        )}
        {clickHome && (
          <ShareButton
            className="ml-2"
            scrolled={isScrolled}
            onClick={() => navigator('/home')}>
            <OverlayHome fontSize={20} fill={isScrolled ? '#000' : 'white'} />
          </ShareButton>
        )}
      </div>
      {type === 'collection' && (
        <div className="flex">
          <BackButton
            kakao={true}
            scrolled={isScrolled}
            onClick={() => openBrowser()}>
            {isScrolled ? (
              <span className="kakaoWrap">
                <KakaoBlackIcon fontSize={20} />
                <span className="kakaoTxt">문의</span>
              </span>
            ) : (
              <KakaoBlackIcon fontSize={20} />
            )}
          </BackButton>
          <BackButton onClick={() => navigator('/home')} style={{padding: '0'}}>
            <HomeIcon fontSize={20} />
          </BackButton>
        </div>
      )}
    </ContentHeader>
  );
}
