import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {SafeArea} from '@aashu-dubey/capacitor-statusbar-safe-area';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';
import {useTranslation} from 'react-i18next';

import {ReadAllNotice} from '../../services/user';
import {
  OpenCategoryModal,
  PathHistories,
  isOpenContentsModal,
  isOpenTempModal,
} from '../../store';
import {user} from '../../store/user';
import styles from './styles.module.scss';

interface Props {
  txt: string | React.ReactNode | undefined;
  isOption:
    | 'home'
    | 'option'
    | 'write'
    | 'write-active'
    | 'none'
    | 'push'
    | 'community';
  isBottomLine?: string;
  isBlack?: boolean;
  isPopup?: boolean;
  hasDropdown?: boolean;
  leftText?: boolean;
  opacity?: 0 | 0.5 | 1;
  back?: () => void;
  clickOption?: () => void;
  titleClick?: () => void;
  noBack?: boolean;
}

export default function ContentsHeader({
  txt,
  isOption,
  isBottomLine,
  isBlack,
  isPopup,
  hasDropdown,
  leftText,
  opacity,
  clickOption,
  titleClick,
  noBack,
  back,
}: Props) {
  const navigator = useNavigate();
  const [hasClicked, setHasClicked] = useState(false);
  const accessToken = useRecoilValue(user);
  const [histories, setHistories] = useRecoilState(PathHistories);
  const openModalStatus = useRecoilValue(isOpenContentsModal);
  const setOpenTempModal = useSetRecoilState(isOpenTempModal);
  const setOpenCategoryModal = useSetRecoilState(OpenCategoryModal);
  const {t} = useTranslation(['layouts'], {keyPrefix: 'ContentsHeader'});
  const historyBack = () => {
    if (back) {
      back();
      return;
    }
    const _histories = JSON.parse(JSON.stringify(histories));
    if (_histories.length > 1) {
      _histories.splice(-1, 1);
      const {path, state} = _histories[_histories.length - 1];
      setHistories(_histories);
      setTimeout(() => {
        navigator(`/${path}`, {
          state,
        });
      }, 0);
    } else {
      const last = _histories.splice(-1, 1);
      if (last) {
        const {path, state} = last;
        setHistories(_histories);
        setTimeout(() => {
          navigator(`/${path}`, {
            state,
          });
        }, 0);
      } else {
        navigator('/home');
      }
    }
  };
  const toggleOption = () => {
    setHasClicked(true);
    clickOption && clickOption();
  };
  const readAll = async () => {
    await ReadAllNotice(accessToken);
  };
  const onTitleClick = () => {
    if (hasDropdown) {
      titleClick ? titleClick() : setOpenCategoryModal(true);
    }
  };
  const getStatusBarHeight = async () => {
    const {height} = await SafeArea.getStatusBarHeight();
    return height; // Ex. 29.090909957885742
  };
  useEffect(() => {
    setHasClicked(openModalStatus);
  }, [openModalStatus]);
  useEffect(() => {
    if (Capacitor.getPlatform() === 'android') {
      getStatusBarHeight();
    }
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
  }, []);

  return (
    <div
      className={`main-content ${styles.contentHeader} ${
        isBlack ? `${styles.black}` : 'bg-white'
      } flex ${Capacitor.getPlatform() === 'android' ? styles.android : ''}`}
      style={{
        borderBottom: isBottomLine === 'none' ? 'none' : '1px solid #E9E7EC',
        zIndex: hasClicked ? 1 : 6,
        opacity: opacity ?? 1,
      }}>
      <div
        className={styles.firstblock}
        style={{display: leftText ? 'none' : 'flex'}}>
        <span
          className={`${
            isBlack
              ? `${styles.contentBack} ${styles.black}`
              : isPopup
              ? `${styles.contentBack} ${styles.popup}`
              : styles.contentBack
          }`}
          style={{cursor: 'pointer', display: noBack ? 'none' : 'inline-block'}}
          onClick={() => historyBack()}></span>
      </div>
      <div className={`${styles.middleblock}  ${leftText && styles.left}`}>
        <div
          className={`${styles.title}`}
          style={{cursor: hasDropdown ? 'pointer' : 'default'}}
          onClick={onTitleClick}>
          {txt}
          <span
            className={`${styles.dropdown}`}
            style={{display: hasDropdown ? 'inline-block' : 'none'}}>
            &nbsp;
          </span>
        </div>
      </div>
      <div className={styles.lastblock}>
        <span
          className={styles.contentHome}
          style={{
            cursor: 'pointer',
            display:
              (isOption === 'home' || isOption === 'community') && !isBlack
                ? 'inline-block'
                : 'none',
            position: isOption === 'community' ? 'relative' : 'absolute',
          }}
          onClick={() => navigator('/home')}></span>
        <span
          className={`icon white-home-icon ${styles.whiteHome}`}
          onClick={() => navigator('/home')}
          style={{
            cursor: 'pointer',
            display: isOption === 'home' && isBlack ? 'inline-block' : 'none',
          }}>
          &nbsp;
        </span>
        <div
          className={styles.options}
          onClick={toggleOption}
          style={{
            display:
              isOption === 'option' || isOption === 'community'
                ? 'flex'
                : 'none',
          }}></div>
        <div
          className={styles.tempSave}
          onClick={() => setOpenTempModal(true)}
          style={{
            display:
              isOption === 'write' || isOption === 'write-active'
                ? 'block'
                : 'none',
            cursor: 'pointer',
          }}>
          {t('tempSave')}
          <span
            className={styles.activeTempSave}
            style={{
              display: isOption === 'write-active' ? 'inline-block' : 'none',
            }}>
            &nbsp;
          </span>
        </div>
        <div
          className={styles.tempSave}
          onClick={() => readAll()}
          style={{
            display: isOption === 'push' ? 'block' : 'none',
            cursor: 'pointer',
          }}>
          {t('readAll')}
        </div>
      </div>
    </div>
  );
}
