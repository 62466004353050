import styled from 'styled-components';

export const MenuItemWrapper = styled.div<{isBorder?: boolean}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: ${props => (props.isBorder ? '1px solid #e9e7ec' : 'none')};
  padding: 8px 0;
`;

export const TextContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 12px;
`;
